import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Ios = () => {
	return (
		<Layout>
			<SEO title="Installing on an iOs device" />
			<h1>Installing this as an app on your iOs device</h1>
			<p>
				If you find this useful, you can easily add it as an app to your phone.
				This will allow you to access it easily as well as offline.
			</p>
			<ol>
				<li>Open this site on Safari (if it isn&apos;t already).</li>
				<li>
					Tap the Share button (this should be the middle icon at the bottom of
					the screen).
				</li>
				<li>Scroll down and tap &apos;Add to Home Screen&apos;.</li>
			</ol>
		</Layout>
	)
}

export default Ios
